import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html',
  styleUrls: ['./mantenimiento.component.css']
})
export class MantenimientoComponent implements OnInit {
  @ViewChild('miModal') modalElement!: ElementRef;
  modalInstance!: Modal;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.openModal();
      }
    });
  }

  openModal() {
    this.modalInstance = new Modal(this.modalElement.nativeElement);
    this.modalInstance.show();
  }

}
