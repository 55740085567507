import { Component, HostListener, OnInit } from '@angular/core';
import { Productos } from 'src/app/interface/interfaces';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @HostListener('scroll', ['$event']) public onScroll($event: any): void {
    const el = document.getElementById('metricas');
    let coords = el!.getBoundingClientRect();
    this.posicionMetrica = (coords.top + window.scrollY) - 300;

    if (Number(window.scrollY) >= Number(this.posicionMetrica)) {
      //console.log("POSICION ENCONTRADA*******************", this.posicionMetrica);
      setInterval(() => {
        this.randomMetricas();
      }, 50)

    }
  }
  servicios: Productos[] = [];

  visitas: number = 0;
  proyectos: number = 0;
  capacitaciones: number = 0;
  colaboraciones: number = 0;
  posicionMetrica: number = 0;
  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.servicios = this.dataService.getProductos();
  }

  navegation(ruta: string) {
    this.dataService.navegation(ruta);
  }


  randomMetricas() {
    if (this.visitas < 184) {
      this.visitas++;
    }

    if (this.proyectos < 83) {
      this.proyectos++;
    }

    if (this.capacitaciones < 158) {
      this.capacitaciones++;
    }

    if (this.colaboraciones < 35) {
      this.colaboraciones++;
    }

  }

}
