<!-- Vertically centered scrollable modal -->
<div #miModal class="modal" tabindex="-1">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">COMUNICADO IMPORTANTE</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <article>
              <br><br>
              <h1 class="text-center">A TODOS NUESTROS ESTIAMDOS CLIENTES</h1>
              <br><br>
              <div>
                <h3 class="text-justify">
                  Se les informa que, el día <span class="titilo-oscuro">Viernes 21 de marzo, desde las 07h00 am hasta
                    las 14h00
                    pm</span> el portal web www.sodinfo.com.ec estará en mantenimiento programado.

                  Una vez terminado el mismo, se autorizarán los documentos electrónicos generados en ese lapso de
                  tiempo.

                  Les recordamos que dudas o sugerencias pueden comunicarnos a los números oficiales 0991605522 –
                  0994071795.
                </h3>
                <br>
                <p>&mdash; SODINFO CIA.LTDA. S.A</p>
              </div>
            </article>
          </div>
          <div class="col-md-12 text-center">
            <img class="img-fluid" src="./assets/mantenimiento.png" alt="" style="width:70%">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
