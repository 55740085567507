<app-menu></app-menu>

<header>
  <div id="slide">
    <section>
      <div class="container" style="padding: 110px 0 205px 0;">
        <div class="row justify-content-center " >
          <div class="col-md-12 col-lg-8 text-center mt-5">
            <h1>SODINFO CIA LTDA</h1>
          </div>
          <div class="col-md-12 col-lg-8 text-center">
            <span class="text-span">#masQueUnaEmpresa</span>
          </div>
          <div class="col-md-12 col-lg-8 text-center pt-5">
            <button type="button" class="btn btn-lg btn-rounder bnt-azul m-2"
            (click)="navegation('/quienes-somos')">Conocenos!</button>

            <button type="button" class="btn btn-lg btn-rounder btn-outline-info bnt-celeste-outline m-2"
            (click)="navegation('/contactos')">Contáctanos</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</header>

<!--Fin slider-->


<!--Breve desc empresa-->

<section class="bg-primary" id="nosotros">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading text-white"> SODINFO CLTDA</h2>
        <span class="text-celeste text-span">#tuEmpresaAmiga!</span>
        <hr class="light my-4">
        <p class="text-faded mb-4">SODINFO CIA.LTDA. Se dedica al diseño, desarrollo y aplicación de Hardware y Software personalizado con la más actual Tecnología puesta al servicio y a la medida de las diferentes líneas de negocios. </p>
        <button type="button" class="btn btn-lg btn-rounder bnt-celeste-outline"
          (click)="navegation('/productos')">Conocenos!</button>
      </div>
    </div>
  </div>


</section>
<!--Fin breve desc-->

<!--Inicio Servicios-->

<section id="servicios">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center mb-5">
        <h2 class="section-heading">Nuestros Productos</h2>
        <span class="text-celeste text-span">#automatizandoProcesos</span>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 text-center item p-3" *ngFor="let producto of servicios | ramdomArray : 3">
        <app-producto [producto] = "producto"></app-producto>
      </div>

      <div class="col-md-12 col-lg-12 p-5 text-center">
        <button type="button" class="btn btn-lg btn-rounder bnt-azul"
        (click)="navegation('/productos')">Ver Productos</button>
      </div>
    </div>
  </div>
</section>

<!--Fin Servicios-->




<!--mettricas-->
<section id="metricas" (window:scroll)="onScroll($event)"class="bg-primary">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-8 mx-auto text-center mb-5">
        <h2 class="section-heading text-white">SODINFO MÉTRICAS</h2>
        <span class="text-celeste text-span">#juntosSomosMas</span>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 text-center mb-3">
            <i class="fas fa-users"></i>
            <h2 class="mb-2 display-3 metrica">{{visitas}}</h2>
            <h4><b>Clientes</b></h4>
          </div>

          <div class="col-md-3 text-center mb-3">
            <i class="fas fa-user-astronaut"></i>
            <h2 class="mb-2 display-3 metrica">{{proyectos}}</h2>
            <h4><b>Proyectos</b></h4>
          </div>

          <div class="col-md-3 text-center mb-3">
            <i class="fas fa-user-graduate"></i>
            <h2 class="mb-2 display-3 metrica">{{capacitaciones}}</h2>
            <h4><b>Capacitaciones</b></h4>
          </div>

          <div class="col-md-3 text-center mb-3">
            <!--<i class="fas fa-users-cog mb-4"></i>-->
            <i class="fas fa-user-tie"></i>
            <h2 class="mb-2 display-3 metrica">{{colaboraciones}}</h2>
            <h4><b>Colaboraciones</b></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--fin de metricas-->


<!-- comentarios-->
  <app-slider-comentarios></app-slider-comentarios>
<!--fin comentarios-->


<!--clientes aliados-->
  <app-clientes-aliados></app-clientes-aliados>
  <!--fin clientes aliados-->

<!--FOOTER-->
<app-footer></app-footer>
<!--fin footer-->
