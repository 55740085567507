import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { ContactsComponent } from './contacts/contacts.component';
import { SharedModule } from '../shared/shared.module';
import { SliderComentariosComponent } from './components/slider-comentarios/slider-comentarios.component';
import { ClientesAliadosComponent } from './components/clientes-aliados/clientes-aliados.component';
import { HeaderComponent } from './components/header/header.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { Profile1Component } from './components/cards/profile1/profile1.component';
import { SliderHeaderComponent } from './components/slider-header/slider-header.component';
import { ProductosDescripcionComponent } from './components/productos-descripcion/productos-descripcion.component';
import { ProductoComponent } from './components/cards/producto/producto.component';
import { HeaderProductoComponent } from './components/header-producto/header-producto.component';
import { LlamanosComponent } from './components/llamanos/llamanos.component';
import { Error404Component } from './components/error404/error404.component';
import { RamdomArrayPipe } from './pipes/ramdom-array.pipe';
import { FormatDescriptionPipe } from './pipes/format-description.pipe';
import { TeamComponent } from './components/cards/team/team.component';
import { UpperFirstLetterPipe } from './pipes/upper-first-letter.pipe';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { FacturacionElectronicaComponent } from './facturacion-electronica/facturacion-electronica.component';


import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HelpFacturacionElectronicaComponent } from './help-facturacion-electronica/help-facturacion-electronica.component';
import { MantenimientoComponent } from './mantenimiento/mantenimiento.component';


@NgModule({
  declarations: [
    HomeComponent,
    ProductsComponent,
    ContactsComponent,
    SliderComentariosComponent,
    ClientesAliadosComponent,
    HeaderComponent,
    QuienesSomosComponent,
    Profile1Component,
    SliderHeaderComponent,
    ProductosDescripcionComponent,
    ProductoComponent,
    HeaderProductoComponent,
    LlamanosComponent,
    Error404Component,
    RamdomArrayPipe,
    FormatDescriptionPipe,
    TeamComponent,
    UpperFirstLetterPipe,
    LoginComponent,
    LoaderComponent,
    FacturacionElectronicaComponent,
    HelpFacturacionElectronicaComponent,
    MantenimientoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    ReactiveFormsModule,
    PdfViewerModule,
    FormsModule
  ],
  exports: [
    MantenimientoComponent
  ]

})
export class PagesModule { }
