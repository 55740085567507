<a [routerLink]="'/'+[producto.url]" class="services-item item-style">
  <div class="services-item-ico">
    <i [class]="producto.icon" class="icon-white"></i>
  </div>
  <div class="services-item-ico-bg">
    <i [class]="producto.icon" class="icon-white"></i>
  </div>
  <h4 class="services-item-title">{{producto.title | titlecase}}</h4>
  <div class="services-item-desc text-break">{{producto.description | formatDescription:150}} <span>...</span></div>
  <!--<button  (click)="navigation(producto.url)" type="button" class="m-3 btn btn-prod"> <b>Leer Más</b></button>-->
 <!--  <span [routerLink]="'/'+[producto.url]" class="link-more text-span">
    <i class="fas fa-arrow-right"></i> LEER MÁS
  </span> -->

  <div class="tag" *ngIf="producto.nuevo">
    <span class="product-new-top text-span">Nuevo</span>
  </div>
</a>
